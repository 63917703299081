import React, { Component } from "react";

//Material Ui Components
import { withStyles } from "@material-ui/core/styles";

//Lottie Components
import Lottie from 'react-lottie';
import NotFoundAnimation from "../drawables/animations/page-not-found-404.json";

//Resources
import { NotFoundScreenStyles } from "./NotFoundScreenResource";

class NotFoundScreen extends Component{
    render(){
        const { classes } = this.props;
        
        //Lottie Options
        const notFoundAnimationOptions = {
            loop: true,
            autoplay: true,
            animationData: NotFoundAnimation,
        };

        return(
            <div className = {classes.mainContainer}>
                <Lottie style = {{height: "100%", width: "100%"}} options = {notFoundAnimationOptions} />
            </div>
        );
    }
}

export default withStyles(NotFoundScreenStyles)(NotFoundScreen);