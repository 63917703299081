import React, { Component } from "react";

//Material Ui Components
import { withStyles } from "@material-ui/core/styles";
import { Typography, Avatar } from "@material-ui/core";

//React Router
import { Link } from "react-router-dom";

//Lottie Components
import Lottie from 'react-lottie';
import BackgroundAnimation from "../drawables/animations/main-background.json";
import LoadingAnimation from "../drawables/animations/loading-animation.json";
import DataNotFoundAnimation from "../drawables/animations/no-data-animation.json";
import NoInternetAnimation from "../drawables/animations/no-internet-animation.json";

//Resources
import { MainScreenStyles } from "./MainScreenResources";
import { MainScreeenStrings } from "./MainScreenResources";

//Api Requests
import { getTrackingRoleList } from "../api/ApiRequests";

//Sweet Alert
import swal from 'sweetalert';

class MainScreen extends Component{
    constructor( props ){
        super( props );

        this.state = {
            roleTrackingList: null,
            screenToRender: null
        }
    }

    async componentDidMount(){
        await this.getRoleTrackingListFromApi();
    }

    getRoleTrackingListFromApi = async() => {
        let roleTrackingList = [], screenToRender = "error";

        await getTrackingRoleList()
        .then( ( { header, message, body, size } ) => {
            if( header === "OK" ){
                roleTrackingList = body;
                screenToRender = "main";
                if( size <= 0 ){
                    screenToRender = "not-found";
                    swal("Advertencia", message, "warning");
                }
            }else{
                swal("Error", message, "error");
            }
        }).catch(error => {
            swal("Error en Conexión", "Revise su conexión a Internet. "+error, "error");
        });

        this.setState( { roleTrackingList, screenToRender } );
    }

    //Render Section
    renderAnimationComponent = ( classes, animationElement ) => {
        const loadingAnimationOptions = {
            loop: true,
            autoplay: true,
            animationData: animationElement,
            rendererSettings: {
                preserveAspectRatio: "none"
            }
        };

        return(
            <div className = {classes.loadingContainer}>
                <Lottie 
                    options = {loadingAnimationOptions}
                    style = {
                        this.props.screenWidth < 600 ?
                            { width: "90vw", height: "90vw"}
                        : this.props.screenWidth < 960 ?
                            { width: "80", height: "80vw"}
                        : this.props.screenWidth < 1280 ?
                            { width: "55vw", height: "55vw"}
                        : this.props.screenWidth < 1920 ?
                            { width: "50vw", height: "50vw"}
                        : { width: "40vw", height: "40vw"}
                    }
                />
            </div>
        );
    }

    renderMainScreen = ( classes, backgroundAnimationOptions ) => {
        return(
            <div className = { classes.mainContainer } >
                <div className = {classes.mainContainer} style = {{zIndex: 999}}>
                    <Typography className = {classes.titleText}>{MainScreeenStrings.titleText}</Typography>

                    {this.state.roleTrackingList.map( ( { roleTrackingName, roleTrackingId, roleTrackingWebView } , indexRoleTracking ) => (
                        <Link className = {classes.linkComponent} style = {{marginBottom: `${(parseFloat(this.props.screenHeight) * 0.03)}px`}} to = { `/${roleTrackingWebView}` } >
                            <div className = {classes.linkContentContainer}>
                                <Avatar src = { "/link-"+ (roleTrackingId) + ".png" } alt = "button-2" className = {classes.linkIcon} />
                                <Typography className = {classes.linkText}>{ roleTrackingName.toLowerCase() }</Typography>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className = {classes.bgContainer} style = {{width: "100vw", position: "absolute", transform: [{ rotate: '90deg'}]}}>
                    <Lottie 
                        options = {backgroundAnimationOptions}
                        style = {
                            this.props.screenWidth < 600 ?
                                { width: "95vw", height: `${(parseFloat(this.props.screenHeight) * 0.6)}px`}
                            : this.props.screenWidth < 960 ?
                                { width: "90vw", height: `${(parseFloat(this.props.screenHeight) * 0.70)}px`}
                            : this.props.screenWidth < 1280 ?
                                { width: "74vw", height: `${(parseFloat(this.props.screenHeight) * 0.75)}px`}
                            : this.props.screenWidth < 1920 ?
                                { width: "65vw", height: `${(parseFloat(this.props.screenHeight) * 0.75)}px`}
                            : { width: "57vw", height: `${(parseFloat(this.props.screenHeight) * 0.75)}px`}
                        }
                    />
                </div>
            </div>
        );
    }

    screenList = {
        "error": ( classes, value ) => this.renderAnimationComponent( classes, NoInternetAnimation ),
        "main": ( classes, backgroundAnimationOptions ) => this.renderMainScreen( classes, backgroundAnimationOptions ),
        "not-found": ( classes, value ) => this.renderAnimationComponent( classes, DataNotFoundAnimation ),
     }

    render(){
        const { classes } = this.props;

        //Lottie Options
        const backgroundAnimationOptions = {
            loop: true,
            autoplay: true,
            animationData: BackgroundAnimation,
            rendererSettings: {
                preserveAspectRatio: "none"
            }
        };

        return(
            <div className = {classes.mainContainer}>
                { this.state.roleTrackingList && this.state.screenToRender && this.screenList && this.screenList[ this.state.screenToRender ] ? 
                    this.screenList[ this.state.screenToRender ]( classes, backgroundAnimationOptions )
                : this.renderAnimationComponent( classes, LoadingAnimation ) }
            </div>
        );
    }
}

export default withStyles(MainScreenStyles)(MainScreen);