import React from "react";

import "./PrivacyPolicy.css";

const PrivacyPolicyComponent = ( props ) => {
    return(
        <div className= "main-container" >
            <header>
                <h1>Política de Privacidad</h1>
            </header>
            <main class = "textContainer" >
                <h2>Política de Privacidad para el uso de la aplicación móvil <b>Publy Track</b> publicada en la Play Store.</h2>
                <section>
                    <p>Al instalar la aplicación usted indica que conoce y entiende los siguientes puntos:</p>
                    <ul>
                        <li>
                            El respeto a tu privacidad está totlamente garantizado.
                        </li>
                        <li>
                            El desarrollador de la aplicación es el único responsable de Publy track y su contenido, no Google.
                        </li>
                        <li>
                            A través de esta aplicación no se recolectan datos personales de los usuarios.
                        </li>
                        <li>
                            No se registran direcciones IP.
                        </li>
                        <li>
                            No se accede a las cuentas de correo de los usuarios.
                        </li>
                        <li>
                            La aplicación no guarda datos, ni hace seguimiento sobre tiempos y horarios de utilización.
                        </li>
                        <li>
                            La aplicación no guarda información relacionado con tu dispositivo, como por ejemplo: fallos, actividad del sistema, ajustes de hardware, tipo de navegador, idioma del navegador.
                        </li>
                        <li>
                            La aplicación no accede a tus contactos, ni agendas.
                        </li>
                        <li>
                            La aplicación no recopila información sobre tu ubicación en tiempo real, solo accederá a ella cuando sea realmente necesario.
                        </li>
                        <li>
                            La aplicación usa una clasificación por edades PEGI 3, es decir, Apto para todos los públicos.
                        </li>
                        <li>
                            Mantenimiento y Soporte: El desarrollador de la aplicación, no Google, estará obligado a proporcionar dicho mantenimiento y soporte.
                        </li>
                        <li>
                            Cargos y cuotas: Cualquier uso de esta aplicación es totalmente gratuito.
                        </li>
                        <li>
                            Cambios en Nuestra Política de Privacidad: Nuestra poítica de privacidad puede estar sujeta a cambios. Cualquier cambio en la Política de privacidad será publicada en esta página, por lo que se recomienda que el usario la revise periódicamente.
                        </li>
                        <li>
                            Contacto: Cualquier duda y sugerencia sobre las politicas de privacidad de la aplicación puede ser enviada y revisada al correo electrónico <b>soporte.sistemas@gmail.com</b>
                        </li>
                    </ul>
                </section>
            </main>

            <footer>
                
            </footer>
        </div>
    );
}

export default PrivacyPolicyComponent;