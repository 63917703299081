import React, { Component } from "react";

//Material Ui Components
import { withStyles } from "@material-ui/core/styles";
import { Typography, InputBase, Button, Paper, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";

import { Search, WhatsApp, PhotoLibrary , PinDrop } from '@material-ui/icons';

//React Router Components
import { useParams, useNavigate  } from "react-router";

//Lottie Components
import Lottie from 'react-lottie';
import LoadingAnimation from "../drawables/animations/loading-animation.json";

import { Carousel } from 'react-carousel-minimal';

//Sweet Alert
import swal from 'sweetalert';

//Api Requests
import { getCouponInformationByGuideNumber } from "../api/ApiRequests";

//Resources
import { SearchByGuideNumberScreenStrings, SearchByGuideNumberScreenStyles } from "./SearchByGuideNumberScreenResources";
import { Colors, Strings } from "../GeneralResources";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SearchByGuideNumberComponent extends Component{
    constructor(props){
        super(props);

        this.state = {
            guideNumber: "",
            currentScreen: "",
            couponInformation: null,
            openMultimediaDialog: false,
        }
    }

    async componentDidMount(){
        let guideNumber = this.props.params.guideNumber;
        this.setState({guideNumber: guideNumber ? guideNumber : ""});

        if(guideNumber){
            await this.doTheSearching(guideNumber.trim());
        }
        
    }

    componentDidUpdate( prevProps, prevState ){
        if(prevState.couponInformation !== this.state.couponInformation && this.state.couponInformation){
            this.textToLeftTransitionAnimation( this.state);
        }
    }

    textToLeftTransitionAnimation = async( { couponInformation } ) => {
        if( !couponInformation ){
            return;
        }

        let { receiverAddress, receiverAddress2 } = couponInformation;
        
        if( !receiverAddress && !receiverAddress2 ){
            return;
        }

        let addressClient = receiverAddress || receiverAddress2;
      
        let addressClientAux = addressClient.toLowerCase();
        let flagText = false;
        await new Promise( resolve => setTimeout( resolve, 500 ) );
        for ( let charIndex = 0; charIndex < addressClient.length; charIndex++ ){
            let addressTextView = document.getElementById("txt-address");
            if( addressTextView ){
                if(charIndex <= 0 ){
                    console.log(addressTextView.offsetWidth+ " - "+ addressTextView.scrollWidth);
                    if( addressTextView.offsetWidth < addressTextView.scrollWidth ){
                        flagText = true;
                    }
                }

                if( flagText ){
                    addressClientAux = addressClientAux.substring(1);
                    addressTextView.innerHTML = addressClientAux;
                    await new Promise( resolve => setTimeout( resolve, 150 ) );
                }
            }
        }


        let addressTextView = document.getElementById("txt-address");
        if( addressTextView ){
            addressTextView.innerHTML = addressClient.toLowerCase();
        }
    }

    capitalizeString = (stringToCapitalize) => {
        return stringToCapitalize.toLowerCase().replace( /\b./g, (a) => { return a.toUpperCase()});
    }

    onSearchButtonClickEvent = async() => {
        if(!this.state.guideNumber || this.state.guideNumber.trim().length <= 0){
            swal("Error: Faltan Datos","Ingrese el Número de la Guía", "error");
            return;
        }

        this.props.navigate( `/oriflame-executive/${ this.state.guideNumber.trim() }`);
        await this.doTheSearching(this.state.guideNumber.trim());
    }



    doTheSearching = async(guideNumber) => {
        this.setState({currentScreen: "loading"});
        await this.loadInformationFromServer(guideNumber);
    }

    loadInformationFromServer = async(guideNumber) => {
        let finalOption = {
            currentScreen: "",
            couponInformation: null,
            showAlert: true,
            titleAlert: "Error",
            messageAlert: "No se realizó ninguna acción",
            iconAlert: "error"
        }
        await getCouponInformationByGuideNumber(guideNumber)
        .then(data => {
            if(data.header === "OK"){
                if(data.size > 0){
                    finalOption.showAlert = false;
                    finalOption.couponInformation = data.body;
                    finalOption.currentScreen = "information";

                    for(let index = 0; index < data.body.photoList.length; index++){
                        data.body.photoList[index]["image"] = `${Strings.multimeadiHost}${data.body.photoList[index].filePath}${data.body.photoList[index].fileName}`;
                    }

                    this.setState({couponInformation: data.body});
                }else{
                    finalOption.titleAlert = "Este pedido no ha sido entregado a Publynext.";
                    finalOption.messageAlert = "";
                    finalOption.iconAlert = "warning";
                }
            }else{
                finalOption.titleAlert = "Error en la Transacción.";
                finalOption.messageAlert = "Se produjo un error al procesar la petición.";
                finalOption.iconAlert = "error";
            }
        }).catch(error => {
            finalOption.titleAlert = "Error en la Conexión.";
            finalOption.messageAlert = "Por favor revise su conexión a Internet.";
            finalOption.iconAlert = "error";
        });


        this.setState({
            couponInformation: finalOption.couponInformation,
            currentScreen: finalOption.currentScreen
        });

        if(finalOption.showAlert){
            swal(finalOption.titleAlert, finalOption.messageAlert, finalOption.iconAlert);
        }
    }

    onChatWhatsappClickEvent = (numberToChat) => {
        window.open(`https://api.whatsapp.com/send?phone=593${numberToChat.substring(1)}`, "WhatsApp");
    }

    onKeyDownEvent = (event) => {
        if(event.key === 'Enter'){
            this.onSearchButtonClickEvent();
        }
    }

    //Render Section
    renderLoadingComponent = (classes) => {
        //Lottie Options
        const loadingAnimationOptions = {
            loop: true,
            autoplay: true,
            animationData: LoadingAnimation,
            rendererSettings: {
                preserveAspectRatio: "none"
            }
        };

        return(
            <div className = {classes.loadingContainer}>
                <Lottie 
                    options = {loadingAnimationOptions}
                    style = {
                        this.props.screenWidth < 600 ?
                            { width: "90vw", height: "90vw"}
                        : this.props.screenWidth < 960 ?
                            { width: "80", height: "80vw"}
                        : this.props.screenWidth < 1280 ?
                            { width: "55vw", height: "55vw"}
                        : this.props.screenWidth < 1920 ?
                            { width: "50vw", height: "50vw"}
                        : { width: "40vw", height: "40vw"}
                    }
                />
            </div>
        );
    }

    // renderReceiverInformationComponent = (classes) => {
    //     return(
    //         <Paper className = {classes.senderInfoContainer}>
    //             <div className = {classes.tittleSectionContainer}>
    //                 <img className = {classes.titleIcon} src = "/receiver-icon.png" alt = "sender-info"/>
    //                 <Typography className = {classes.titleSectionText}>Información del Destinatario</Typography>
    //             </div>

    //             <div className = {classes.globalContainer}>
    //                 <div className = {classes.receiverItemSectionContainerLeft}>
    //                     <Typography className = {classes.receiverItemTitleText}>Nombre:</Typography>
    //                     <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receiverName ? this.capitalizeString(this.state.couponInformation.receiverName) : "Nombre del Destinatario"}</Typography>
    //                 </div>

    //                 <div className = {classes.receiverItemSectionContainerRight}>
    //                     <Typography className = {classes.receiverItemTitleText}>Ciudad:</Typography>
    //                     <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.destinationCity ? this.capitalizeString(this.state.couponInformation.destinationCity) : "Nombre de la Ciudad de destino"}</Typography>
    //                 </div>
    //             </div>

    //             <div className = {classes.globalContainer}>
    //                 <div className = {classes.receiverItemSectionContainerLeft}>
    //                     <Typography className = {classes.receiverItemTitleText}>Dirección:</Typography>
    //                     <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receiverAddress ? this.capitalizeString(this.state.couponInformation.receiverAddress) : "Dirección del Destinatario"}</Typography>
    //                 </div>

    //                 <div className = {classes.receiverItemSectionContainerRight}>
    //                     <Typography className = {classes.receiverItemTitleText}>Celular:</Typography>
    //                     <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receiverCellPhone ? this.capitalizeString(this.state.couponInformation.receiverCellPhone) : "Sin Datos"}</Typography>
    //                 </div>
    //             </div>
    //         </Paper>
    //     );
    // }

    // renderDeliveryingInformationComponent = (classes) => {
    //     return(
    //         <Paper className = {classes.senderInfoContainer}>
    //             <div className = {classes.tittleSectionContainer}>
    //                 <img className = {classes.titleIcon} src = "/delivery-icon.png" alt = "sender-info"/>
    //                 <Typography className = {classes.titleSectionText}>Información de Entrega:</Typography>
    //             </div>

    //             <div className = {classes.itemSectionContainer}>
    //                 <Typography className = {classes.deliveryingTitleText}>Recibido en Bodega:</Typography>
    //                 <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receptionDate ? this.state.couponInformation.receptionDate.split(" ")[0] : "Sin Asignar"}</Typography>
    //             </div>

    //             <div className = {classes.itemSectionContainer}>
    //                 <Typography className = {classes.deliveryingTitleText}>Asignado a Courier:</Typography>
    //                 <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.couponAssigmentDate && this.state.couponInformation.couponStatusName && this.state.couponInformation.couponStatusName !== "AU" ? this.state.couponInformation.couponAssigmentDate.split(" ")[0] : "Sin Asignar"}</Typography>
    //             </div>

    //             <div className = {classes.itemSectionContainer}>
    //                 <Typography className = {classes.deliveryingTitleText}>Nombre Courier:</Typography>
    //                 <Typography className = {classes.deliveryingContentText} >{this.state.couponInformation && this.state.couponInformation.couponStatusName && this.state.couponInformation.courierAssignedName && this.state.couponInformation.couponStatusName !== "AU"  ? this.capitalizeString(this.state.couponInformation.courierAssignedName) : "Sin Asignar"}</Typography>
    //             </div>

    //             {this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus !== "AU" ?
    //                 <div className = {classes.itemSectionContainer}>
    //                     <Typography className = {classes.deliveryingTitleText}>Celular de Contacto:</Typography>

    //                     <div className = {classes.deliveryingStatusContentText}>
    //                         <Typography variant = "inherit" style = {{color: Colors.primary, textTransform: "capitalize", marginRight: "2vw"}} noWrap = {false}>{this.state.couponInformation && this.state.couponInformation.courierAssignedCellPhone ? this.state.couponInformation.courierAssignedCellPhone : "Sin Estado"}</Typography>
    //                         <Button
    //                             variant = "contained"
    //                             color = "primary"
    //                             size = "small"
    //                             onClick = {() => this.onChatWhatsappClickEvent(this.state.couponInformation.courierAssignedCellPhone)}
    //                             className = {classes.buttonsStyleAux}
    //                             endIcon = {<WhatsApp/>}
    //                         >
    //                             Chatear por WhatsApp
    //                         </Button>
    //                     </div>
    //                 </div>
    //             : null}

    //             <div className = {classes.itemSectionContainer}>
    //                 <Typography className = {classes.deliveryingTitleText} >Estado:</Typography>
    //                 <Typography className = {classes.statusContentText} noWrap = {true} style = {{color: Colors.secondary, fontWeight: "bold"}}>{this.state.couponInformation && this.state.couponInformation.couponStatusName ? this.capitalizeString(this.state.couponInformation.couponStatusName) : "Sin Estado"}</Typography>
    //             </div>

    //             {this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
    //                 <div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Fecha Entrega:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByDate && this.state.couponInformation.couponStatus === "EN"  ? this.state.couponInformation.receivedByDate.split(" ")[0] : "Sin Datos"}</Typography>
    //                     </div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Recibido Por:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByName) : "Sin Datos"}</Typography>
    //                     </div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Parentesco:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByRelationshipName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByRelationshipName) : "Sin Datos"}</Typography>
    //                     </div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Observación:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByRelationshipName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByObservation) : "Sin Datos"}</Typography>
    //                     </div>
    //                     <div className = {classes.itemSectionContainer} style = {{marginBottom: 10, alignItems: "center", justifyContent: "center"}}>
    //                         <Button
    //                             variant = "contained"
    //                             color = "primary"
    //                             size = "small"
    //                             onClick = {() => this.setState({openMultimediaDialog: true})}
    //                             className = {classes.buttonsStyle}
    //                             endIcon = {<PhotoLibrary/>}
    //                         >
    //                             Ver Prueba de Entrega / Fotos
    //                         </Button>
    //                     </div>
    //                 </div>
    //             : null}

    //             {this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "RE" ?
    //                 <div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Fecha Entrega:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.rejectingDate && this.state.couponInformation.couponStatus === "RE"  ? this.state.couponInformation.rejectingDate.split(" ")[0] : "Sin Datos"}</Typography>
    //                     </div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Motivo:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.rejectingReasonName && this.state.couponInformation.couponStatus === "RE"  ? this.capitalizeString(this.state.couponInformation.rejectingReasonName) : "Sin Datos"}</Typography>
    //                     </div>
    //                     <div className = {classes.itemSectionContainer}>
    //                         <Typography className = {classes.deliveryingTitleText}>Observación:</Typography>
    //                         <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.rejectingObservation && this.state.couponInformation.couponStatus === "RE"  ? this.capitalizeString(this.state.couponInformation.rejectingObservation) : "Sin Datos"}</Typography>
    //                     </div>
    //                 </div>
    //             : null}
    //         </Paper>
    //     );
    // }

    renderMultimediaDialog = (classes) => {
        return(
            
                <Dialog
                    open = {this.state.openMultimediaDialog}
                    TransitionComponent = {Transition}
                    keepMounted
                    fullWidth = {true}
                    maxWidth = {'lg'}
                    onClose = {() => this.setState({openMultimediaDialog: false})}
                    aria-labelledby = "alert-dialog-slide-title"
                    aria-describedby = "alert-dialog-slide-description"
                >
                    <DialogContent  className = {classes.dialogContainer}>
                        {this.state.couponInformation && this.state.couponInformation.photoList ?
                            <Carousel
                                data = {this.state.couponInformation.photoList}
                                time = {1500}
                                width = {2080}
                                height = {parseFloat(this.props.screenHeight) * 0.75}
                                radius = "10px"
                                slideNumber = {true}
                                slideNumberStyle = {{ fontSize: '20px', fontWeight: 'bold', color: Colors.primary }}
                                automatic = {true}
                                dots = {true}
                                pauseIconColor = "white"
                                pauseIconSize = "40px"
                                slideBackgroundColor = "darkgrey"
                                slideImageFit = "contain"
                                thumbnails = {false}
                                style = {{
                                    textAlign: "center",
                                    maxWidth: "1000",
                                    maxHeight: "800",
                                    margin: "auto",
                                }}
                            />
                        : null}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant = "contained"
                            color = "primary"
                            size = "small"
                            onClick = {() => this.setState({openMultimediaDialog: false})}
                            style = {{marginRight: 25}}
                            className = {classes.buttonsStyle}
                        >
                            Cerrar 
                        </Button>
                    </DialogActions>
                </Dialog>
            
        );
    }

    renderSearchComponent = (classes) => {
        return(
            <div className = {classes.searchComponent}>
                <div className = {classes.searchContainer}>
                    <div className = {classes.searchIcon}>
                        <Search/>
                    </div>
                    <InputBase
                        placeholder = {SearchByGuideNumberScreenStrings.searchHintText}
                        value = {this.state.guideNumber}
                        onKeyDown = {this.onKeyDownEvent}
                        onChange = {(event) => this.setState({guideNumber: event.target.value})}
                        onBlur = {() => this.setState({guideNumber: this.state.guideNumber.trim()})}
                        className = {classes.inputContainer}
                        classes = {{
                            root: classes.inputRoot,
                            input: classes.inputComponent,
                        }}
                        inputProps = {{ 'aria-label': 'search' }}
                    />
                </div>

                <Button onClick = {this.onSearchButtonClickEvent}  variant = "contained" className = {classes.searchButton}>{SearchByGuideNumberScreenStrings.searchButtonText}</Button>
            </div>
        );
    }

    //Start Here
    componentToRender = {
        loading: (classes) => this.renderLoadingComponent(classes),
        information: (classes) => this.renderInformationComponent(classes),
    }

    renderInformationComponent = ( classes ) => {
        return(
            <div className = { classes.informationContainer } >
                { this.renderReceiverInformationComponent( classes ) }
                { this.renderTraceabilityInformation( classes ) }
                { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus !== "AU" ?
                    this.renderCourierInformation( classes ) 
                : null }
                { this.renderComplainingInformationComponent( classes ) }
            </div>
        );
    }

    renderReceiverInformationComponent = ( classes ) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = { classes.traceabilityInformationContainer } >
                    <p className = { classes.nameText } ><b>Nombre del Destinatario</b></p>
                    <p className = { classes.nameText } >{ this.state.couponInformation && this.state.couponInformation.receiverName ? this.capitalizeString(this.state.couponInformation.receiverName) : "Nombre del Destinatario" }</p>

                    <div className = { classes.adressContainer } >
                        <PinDrop className = { classes.addressIcon } />
                        <p id = "txt-address" onMouseOver  = { () => this.textToLeftTransitionAnimation( this.state) } className = { classes.addressText } >{ this.state.couponInformation.receiverAddress.toLowerCase() || this.state.couponInformation.receiverAddress2.toLowerCase() || "Sin Dirección de Entrega" }</p>
                    </div>

                    <div className = { classes.courierInformationItem } >
                        <p className = { classes.statusText } ><b>Estado:</b></p>
                        <p className = { classes.statusText2 } ><b style = { { color: Colors.secondary } } >{ this.state.couponInformation && this.state.couponInformation.couponStatusName ? this.capitalizeString(this.state.couponInformation.couponStatusName) : "Sin Estado" }</b></p>
                    </div>

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Fecha de Entrega:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.receivedByDate && this.state.couponInformation.couponStatus === "EN"  ? this.state.couponInformation.receivedByDate.split(" ")[0] : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Recibido por:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.receivedByName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByName) : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Parentesco:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.receivedByRelationshipName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByRelationshipName) : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Observación:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.receivedByRelationshipName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByObservation) : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "RE" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Fecha de Visita:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.rejectingDate && this.state.couponInformation.couponStatus === "RE"  ? this.state.couponInformation.rejectingDate.split(" ")[0] : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "RE" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Motivo:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.rejectingReasonName && this.state.couponInformation.couponStatus === "RE"  ? this.capitalizeString(this.state.couponInformation.rejectingReasonName) : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "RE" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle3 } ><b>Observación:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.rejectingObservation && this.state.couponInformation.couponStatus === "RE"  ? this.capitalizeString(this.state.couponInformation.rejectingObservation) : "Sin Datos" }</p>
                        </div>
                    : null }
                        
                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                        <div className = {classes.itemSectionContainer} style = {{marginBottom: 10, alignItems: "center", justifyContent: "center"}}>
                            <Button
                                variant = "contained"
                                color = "primary"
                                size = "small"
                                onClick = {() => this.setState({openMultimediaDialog: true})}
                                className = {classes.buttonsStyle}
                                endIcon = {<PhotoLibrary/>}
                            >
                                Ver Prueba de Entrega / Fotos
                            </Button>
                        </div>
                    : null }
                </div>
            </Paper>
        );
    }

    renderTraceabilityInformation = ( classes ) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer}>
                    <img className = {classes.titleIcon} src = "/traceability-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Trazabilidad</Typography>
                </div>

                <div className = { classes.traceabilityInformationContainer } >
                    <div className = { classes.courierInformationItem } >
                        <p className = { classes.traceabilityTitle } ><b>Recibido en Publynext:</b></p>
                        <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.receptionDate ? this.state.couponInformation.receptionDate.split(" ")[0] : "Sin Asignar" }</p>
                    </div>

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus !== "AU" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle } ><b>Asignado a Courier:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.couponAssigmentDate && this.state.couponInformation.couponStatusName && this.state.couponInformation.couponStatusName !== "AU" ? this.state.couponInformation.couponAssigmentDate.split(" ")[0] : "Sin Asignar" }</p>
                        </div> 
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle } ><b>Recibido por Cliente:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.receivedByDate && this.state.couponInformation.couponStatus === "EN"  ? this.state.couponInformation.receivedByDate.split(" ")[0] : "Sin Datos" }</p>
                        </div>
                    : null }

                    { this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "RE" ?
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle } ><b>Fecha de Visita:</b></p>
                            <p className = { classes.itemText } >{ this.state.couponInformation && this.state.couponInformation.rejectingDate && this.state.couponInformation.couponStatus === "RE"  ? this.state.couponInformation.rejectingDate.split(" ")[0] : "Sin Datos" }</p>
                        </div>
                    : null }
                </div>
            </Paper>
        );
    }

    renderCourierInformation = ( classes ) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer}>
                    <img className = {classes.titleIcon} src = "/courier-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Datos de Courier</Typography>
                </div>

                <div className = { classes.courierInformationContainer } >
                    <div className = { classes.courierInformationItem } >
                        <p className = { classes.traceabilityTitle2 } ><b>Nombre de Courier:</b></p>
                        <p className = { classes.itemText2 } >{this.state.couponInformation && this.state.couponInformation.couponStatusName && this.state.couponInformation.courierAssignedName && this.state.couponInformation.couponStatusName !== "AU"  ? this.capitalizeString(this.state.couponInformation.courierAssignedName) : "Sin Asignar"}</p>
                    </div>

                    <div className = { classes.courierInformationItem2 } >
                        <div className = { classes.courierInformationItem } >
                            <p className = { classes.traceabilityTitle2 } ><b>Celular de Contacto:</b></p>
                            <p className = { classes.itemText2 } variant = "inherit" >{this.state.couponInformation && this.state.couponInformation.courierAssignedCellPhone ? this.state.couponInformation.courierAssignedCellPhone : "Sin Estado"}</p>
                        </div>
                        <Button
                            variant = "contained"
                            color = "primary"
                            size = "small"
                            onClick = {() => this.onChatWhatsappClickEvent(this.state.couponInformation.courierAssignedCellPhone)}
                            className = {classes.buttonsStyleAux}
                            endIcon = {<WhatsApp/>}
                        >
                            Chatear por WhatsApp
                        </Button>
                    </div>
                </div>
            </Paper>
        );
    }

    renderComplainingInformationComponent = (classes) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer2}>
                    <img className = {classes.titleIcon} src = "/complain-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Reportar atraso de Entrega</Typography>
                </div>

                <div className = {classes.complainingTitleContainer}>
                    <Typography className = {classes.complainingTitleText} style = {{textAlign: "center"}}>Reportar Llamando al {this.state.couponInformation && this.state.couponInformation.complainingPhoneNumber ? this.state.couponInformation.complainingPhoneNumber : "Sin Datos"} o </Typography>
                    <Button
                        variant = "contained"
                        color = "primary"
                        fullWidth = {false}
                        size = "small"
                        onClick = {() => this.onChatWhatsappClickEvent(this.state.couponInformation.complainingPhoneNumber)}
                        className = {classes.buttonsStyle}
                        endIcon = {<WhatsApp/>}
                    >
                        Reportar por WhatsApp 
                    </Button>
                </div>
            </Paper>
        );
    }

    render(){
        const {classes} = this.props;
        
        return(
            <div className = {classes.mainContainer}>
                <Typography className = {classes.titleText}>{SearchByGuideNumberScreenStrings.titleText}</Typography>
                
                {this.renderSearchComponent(classes)}

                {this.state.currentScreen && this.state.currentScreen.trim().length > 0 && this.componentToRender[this.state.currentScreen] ?
                    this.componentToRender[this.state.currentScreen](classes)
                : null}
                {this.renderMultimediaDialog(classes)}
            </div>
        );
    }
}

const SearchByGuideNumberScreen = (props) => (
    <SearchByGuideNumberComponent
        {...props}
        params = { useParams() }
        navigate = { useNavigate () }
    />
);
export default withStyles(SearchByGuideNumberScreenStyles)(SearchByGuideNumberScreen);