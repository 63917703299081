import React, {Component} from 'react';

//React Router
import { Routes, Route } from "react-router-dom";

//Components
import MainScreen from './main/MainScreen';
import SearchByGuideNumberScreen from './searchByGuideNumber/SearchByGuideNumberScreen';
import SearchByCodeNumberScreen from './searchByCodeNumber/SearchByCodeNumberScreen';
import NotFoundScreen from './notFound/NotFoundScreen';
import PrivacyPolicyComponent from "./privacy-policy/PrivacyPolicyView"

export default class App extends Component{
	constructor(props){
        super(props);

        //Status Vars
        this.state = {
            screenHeight: window.innerHeight <= 600 ? (window.screen.width * 0.75) : window.innerHeight,
			screenInnerHeight: window.innerHeight,
			screenWidth: window.innerWidth,
        }
    }

    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ 
			screenHeight: window.screen.height <= 600 ? (window.screen.width * 0.75) : window.innerHeight >= this.state.screenInnerHeight ? window.innerHeight : this.state.screenInnerHeight,
			screenWidth: window.innerWidth,
        });
    };

	render(){
		return(
			<div>
				<Routes>
					<Route exact path = "/" element = {<MainScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />
					
					<Route exact path = "oriflame-executive" element = {<SearchByGuideNumberScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />
					<Route exact path = "oriflame-executive/:guideNumber" element = {<SearchByGuideNumberScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />
					
					<Route exact path = "oriflame-logistics" element = {<SearchByCodeNumberScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />
					<Route exact path = "oriflame-logistics/:codeNumber" element = {<SearchByCodeNumberScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />

					<Route exact path = "publynext-logistics" element = {<SearchByCodeNumberScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />
					<Route exact path = "publynext-logistics/:codeNumber" element = {<SearchByCodeNumberScreen screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />

					<Route exact path = "politica-privacidad" element = {<PrivacyPolicyComponent screenHeight = {this.state.screenHeight} screenWidth = {this.state.screenWidth} />} />
					
					<Route  path = "/*" element = {<NotFoundScreen/>} />
				</Routes>
			</div>
		);
	}
}