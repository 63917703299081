import { Colors } from "../GeneralResources";

export const SearchByCodeNumberScreenStrings = {
    titleText: "Tracking de Envío",
    searchHintText: "Ingrese el número del Cupón",
    searchButtonText: "Buscar",
};

export const SearchByCodeNumberScreenStyles = (theme) => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            height: props => "100vh",
        },
        [theme.breakpoints.up('sm')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('md')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('lg')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('xl')]: {
            height: props => props.screenHeight,
        },
    },

    titleText: {
        fontWeight: "bold",
        color: Colors.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: "6vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.01)}px`,
            marginTop: props => `${(parseFloat(props.screenHeight) * 0.03)}px`,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "4vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.01)}px`,
            marginTop: props => `${(parseFloat(props.screenHeight) * 0.03)}px`,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "2.4vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.015)}px`,
            marginTop: props => `${(parseFloat(props.screenHeight) * 0.03)}px`,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "2.2vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.015)}px`,
            marginTop: props => `${(parseFloat(props.screenHeight) * 0.03)}px`,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "2vw",
            marginTop: props => `${(parseFloat(props.screenHeight) * 0.03)}px`,
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.02)}px`,
        },
    },

    searchComponent: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            width: "90vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.01)}px`,
        },
        [theme.breakpoints.up('sm')]: {
            width: "45vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.01)}px`,
        },
        [theme.breakpoints.up('md')]: {
            width: "38vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.015)}px`,
        },
        [theme.breakpoints.up('lg')]: {
            width: "30vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.015)}px`,
        },
        [theme.breakpoints.up('xl')]: {
            width: "25vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.02)}px`,
        },
    },

    searchContainer: {
        display: "flex",
        flexDirection: "row",
        border: `1px solid ${Colors.primary}`,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        flex: 1,
    },

    searchIcon: {
        height: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        color: Colors.primary,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 1.5),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 1.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 1.5),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 1.2),
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0, 2),
        },
    },

    inputContainer: {
        flex: 1,
        fontWeight: "bold",
    },

    inputRoot: {
        color: 'inherit',
    },

    inputComponent: {
        padding: theme.spacing(1, 1, 1, 0),
        transition: theme.transitions.create('width'),
        color: Colors.primary,
    },

    searchButton: {
        textTransform: "none",
        fontWeight: "bold",
        padding: theme.spacing(0, 2),
        backgroundColor: Colors.primary,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        color: "white",
        '&:hover': {
            backgroundColor: Colors.secondary,
        }
    },

    loadingContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },

    informationContainer: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        width: "100%"
    },

    senderInfoContainer: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            width: "90vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.01)}px`,
        },
        [theme.breakpoints.up('sm')]: {
            width: "90vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.01)}px`,
        },
        [theme.breakpoints.up('md')]: {
            width: "90vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.015)}px`,
        },
        [theme.breakpoints.up('lg')]: {
            width: "85vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.015)}px`,
        },
        [theme.breakpoints.up('xl')]: {
            width: "60vw",
            marginBottom: props => `${(parseFloat(props.screenHeight) * 0.02)}px`,
        },
    },

    tittleSectionContainer: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: Colors.primary,
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.5, 1.5),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0.8, 1.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0.3, 1.5),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0.4, 1.2),
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0.5, 2),
        },
    },

    titleSectionText: {
        fontWeight: "bold",
        color: "white",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.5vw"
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.2vw"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.4vw"
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.3vw"
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.85vw"
        },
    },

    titleIcon: {
        [theme.breakpoints.down('sm')]: {
            width: "5.2vw",
            height: "4.5vw",
            marginRight: "2vw",
        },
        [theme.breakpoints.up('sm')]: {
            width: "3.8vw",
            height: "3vw",
            marginRight: "2vw",
        },
        [theme.breakpoints.up('md')]: {
            width: "2vw",
            height: "1.6vw",
            marginRight: "1vw",
        },
        [theme.breakpoints.up('lg')]: {
            width: "1.8vw",
            height: "1.5vw",
            marginRight: "1vw",
        },
        [theme.breakpoints.up('xl')]: {
            width: "1.3vw",
            height: "1vw",
            marginRight: "0.5vw",
        },
    },

    itemSectionContainer: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 1),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3.5),
            alignItems: "center",
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 3),
            alignItems: "center",
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 1),
            alignItems: "center",
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0, 4),
            alignItems: "center",
        },
    },

    itemTitleText: {
        fontWeight: "bold",
        color: Colors.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            flex: 1,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            flex: 2,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            flex: 2,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            flex: 1,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 1,
        },
    },

    itemContentText: {
        color: Colors.primary,
        textTransform: "capitalize",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            flex: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            flex: 10,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            flex: 9,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            flex: 7,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 7,
        },
    },

    deliveryingTitleText: {
        fontWeight: "bold",
        textAlign: "right",
        color: Colors.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            flex: 2,
            paddingRight: "3vw",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            flex: 2,
            paddingRight: "3vw",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            flex: 1,
            paddingRight: "2vw",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            flex: 3,
            paddingRight: "2vw",
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 1,
            paddingRight: "1vw",
        },
    },

    deliveryingContentText: {
        color: Colors.primary,
        textTransform: "capitalize",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            flex: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            flex: 6,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            flex: 4,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            flex: 13,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 5,
        },
    },

    deliveryingStatusContentText: {
        color: Colors.primary,
        display: "flex",
        textTransform: "capitalize",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            flex: 3,
            flexDirection: "column", 
            alignContent: "center"
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            flex: 6,
            flexDirection: "row", 
            alignContent: "center"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            flex: 4,
            flexDirection: "row", 
            alignContent: "center"
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            flex: 13,
            flexDirection: "row", 
            alignContent: "center"
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 5,
            flexDirection: "row", 
            alignContent: "center"
        },
    },


    statusContentText: {
        color: Colors.primary,
        textTransform: "capitalize",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.35vw",
            flex: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "2.15vw",
            flex: 6,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.5vw",
            flex: 4,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.3vw",
            flex: 13,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "1vw",
            flex: 5,
        },
    },

    dialogContainer: {
        [theme.breakpoints.down('sm')]: {
            height: props => parseFloat(props.screenHeight) * 0.31,
        },
        [theme.breakpoints.up('sm')]: {
            height: props => parseFloat(props.screenHeight) * 0.75,
        },
        [theme.breakpoints.up('md')]: {
            height: props => parseFloat(props.screenHeight) * 0.75,
        },
        [theme.breakpoints.up('lg')]: {
            height: props => parseFloat(props.screenHeight) * 0.75,
        },
        [theme.breakpoints.up('xl')]: {
            height: props => parseFloat(props.screenHeight) * 0.75,
        },
    },

    buttonsStyle: {
        textTransform: "capitalize",
        backgroundColor: Colors.secondary,
        borderRadius: 25,
        [theme.breakpoints.down('sm')]: {
            padding: "0.7vw 9vw",
        },
        [theme.breakpoints.up('sm')]: {
            padding: "0.18vw 4vw",
        },
        [theme.breakpoints.up('md')]: {
            padding: "0.15vw 2.5vw",
        },
        [theme.breakpoints.up('lg')]: {
            padding: "0.15vw 2vw",
        },
        [theme.breakpoints.up('xl')]: {
            padding: "0.15vw 2vw",
        },
        "&:hover": {
            backgroundColor: Colors.primary
        }
    },

    buttonsStyleAux: {
        textTransform: "capitalize",
        backgroundColor: Colors.secondary,
        borderRadius: 25,
        [theme.breakpoints.down('sm')]: {
            padding: "0.7vw 1vw",
        },
        [theme.breakpoints.up('sm')]: {
            padding: "0.18vw 4vw",
        },
        [theme.breakpoints.up('md')]: {
            padding: "0.15vw 2.5vw",
        },
        [theme.breakpoints.up('lg')]: {
            padding: "0.15vw 2vw",
        },
        [theme.breakpoints.up('xl')]: {
            padding: "0.15vw 2vw",
        },
        "&:hover": {
            backgroundColor: Colors.primary
        }
    },

    globalContainer: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: "row",
        },
        [theme.breakpoints.up('xl')]: {
            flexDirection: "row",
        },
    },

    receiverItemSectionContainerLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 2,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2.5),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 3),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 3),
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0, 4),
        },
    },

    receiverItemSectionContainerRight: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 2.5),
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 3),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 1),
        },
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(0, 4),
        },
    },

    receiverItemTitleText: {
        fontWeight: "bold",
        color: Colors.primary,
        textAlign: "right",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            paddingRight: "3vw",
            flex: 1,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            paddingRight: "3vw",
            flex: 1,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            paddingRight: "2vw",
            flex: 2,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            paddingRight: "2vw",
            flex: 1,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 1,
            paddingRight: "1vw",
        },
    },

    receiverItemContentText: {
        color: Colors.primary,
        textTransform: "capitalize",
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
            flex: 4,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            flex: 7,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            flex: 9,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            flex: 5,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            flex: 6,
        },
    },

    complainingTitleText: {
        fontWeight: "bold",
        color: Colors.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: "3.15vw",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.95vw",
            marginRight: "1vw",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.3vw",
            marginRight: "1vw",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.1vw",
            marginRight: "1vw",
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: "0.8vw",
            marginRight: "0.8vw",
        },
    },

    complainingTitleContainer: {
        display: "flex",
        marginBottom: 7,
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            padding: theme.spacing(1, 4),
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: "row",
            padding: theme.spacing(1, 4),
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
            padding: theme.spacing(1, 4),
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: "row",
            padding: theme.spacing(1, 4),
        },
        [theme.breakpoints.up('xl')]: {
            flexDirection: "row",
            padding: theme.spacing(1, 4),
        },
    },
});