import { Colors } from "../GeneralResources";

export const MainScreeenStrings = {
    titleText: "Elija su Perfil",
    linkOptionOriflame: "Empresario Oriflame",
    linkOptionCourier: "Courier Publynext",
    linkOptionLogistics: "Asesor Logística",
};

export const MainScreenStyles = (theme) => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            height: props => "100vh",
        },
        [theme.breakpoints.up('sm')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('md')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('lg')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('xl')]: {
            height: props => props.screenHeight,
        },
    },

    bgContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "url(/main-background.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        [theme.breakpoints.down('sm')]: {
            height: props=> "100vh",
        },
        [theme.breakpoints.up('sm')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('md')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('lg')]: {
            height: props => props.screenHeight,
        },
        [theme.breakpoints.up('xl')]: {
            height: props => props.screenHeight,
        },
    },

    linkComponent: {
        backgroundColor: Colors.primary,
        color: "white",
        textDecoration: "none",
        borderRadius: 50,
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: "6vw",
            padding: props => `${(parseFloat(props.screenHeight) * 0.02)}px 5vw`,
            width: "75vw"
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "5vw",
            padding: props => `${(parseFloat(props.screenHeight) * 0.02)}px 5vw`,
            width: "65vw"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "3.6vw",
            padding: props => `${(parseFloat(props.screenHeight) * 0.02)}px 5vw`,
            width: "40vw"
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "2.5vw",
            padding: props => `${(parseFloat(props.screenHeight) * 0.02)}px 5vw`,
            width: "30vw"
        },
        [theme.breakpoints.up('xl')]: {           
            fontSize: "2.3vw",
            padding: props => `${(parseFloat(props.screenHeight) * 0.02)}px 5vw`,
            width: "27vw"
        },

        '&:hover': {
            backgroundColor: Colors.secondary,
        }
    },

    linkContentContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },

    linkText: {
        color: "white",
        textDecoration: "none",
        textTransform: "capitalize",
        [theme.breakpoints.down('sm')]: {
            fontSize: "6vw",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "5vw",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "3.6vw",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "2.5vw",
        },
        [theme.breakpoints.up('xl')]: {           
            fontSize: "2.3vw",
        },
    },

    linkIcon: {
        [theme.breakpoints.down('sm')]: {
            height: "9vw",
            width: "9vw",
            marginRight: "3.5vw",
        },
        [theme.breakpoints.up('sm')]: {
            height: "7vw",
            width: "7vw",
            marginRight: "3.5vw",
        },
        [theme.breakpoints.up('md')]: {
            height: "4vw",
            width: "4vw",
            marginRight: "2vw",
        },
        [theme.breakpoints.up('lg')]: {
            height: "3.1vw",
            width: "3.1vw",
            marginRight: "1.5vw",
        },
        [theme.breakpoints.up('xl')]: {           
            height: "3.1vw",
            width: "3.1vw",
            marginRight: "1.5vw",
        },
    },

    titleText: {
        fontWeight: "bold",
        color: Colors.secondary,
        marginBottom: props => `${(parseFloat(props.screenHeight) * 0.04)}px`,
        [theme.breakpoints.down('sm')]: {
            fontSize: "9vw",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "7vw",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "4.5vw",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "3.6vw",
        },
        [theme.breakpoints.up('xl')]: {           
            fontSize: "3vw",
        },
    },
});