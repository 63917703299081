import React, { Component } from "react";

//Material Ui Components
import { withStyles } from "@material-ui/core/styles";
import { Typography, InputBase, Button, Paper, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";

import { Search, WhatsApp, PhotoLibrary } from '@material-ui/icons';

//React Router Components
import { useParams } from "react-router";

//Lottie Components
import Lottie from 'react-lottie';
import LoadingAnimation from "../drawables/animations/loading-animation.json";

import { Carousel } from 'react-carousel-minimal';

//Sweet Alert
import swal from 'sweetalert';

//Api Requests
import { getCouponInformationByCode } from "../api/ApiRequests";

//Resources
import { SearchByCodeNumberScreenStrings, SearchByCodeNumberScreenStyles } from "./SearchByCodeNumberScreenResources";
import { Colors, Strings } from "../GeneralResources";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SearchByCodeNumberComponent extends Component{
    constructor(props){
        super(props);

        this.state = {
            codeNumber: "",
            currentScreen: "",
            couponInformation: null,
            openMultimediaDialog: false
        }
    }

    componentDidMount(){
        let codeNumber = this.props.params.codeNumber;
        this.setState({codeNumber: codeNumber ? codeNumber : ""});

        if(codeNumber){
            this.doTheSearching(codeNumber.trim());
        }
    }

    capitalizeString = (stringToCapitalize) => {
        return stringToCapitalize.toLowerCase().replace( /\b./g, (a) => { return a.toUpperCase()});
    }

    onSearchButtonClickEvent = () => {
        if(!this.state.codeNumber || this.state.codeNumber.trim().length <= 0){
            swal("Error: Faltan Datos","Ingrese el Número de la Guía", "error");
            return;
        }

        this.doTheSearching(this.state.codeNumber.trim());
    }

    doTheSearching = (codeNumber) => {
        this.setState({currentScreen: "loading"});
        this.loadInformationFromServer(codeNumber);
    }

    loadInformationFromServer = async(codeNumber) => {
        let finalOption = {
            currentScreen: "",
            couponInformation: null,
            showAlert: true,
            titleAlert: "Error",
            messageAlert: "No se realizó ninguna acción",
            iconAlert: "error"
        }
        await getCouponInformationByCode(codeNumber)
        .then(data => {
            if(data.header === "OK"){
                if(data.size > 0){
                    finalOption.showAlert = false;
                    finalOption.couponInformation = data.body;
                    finalOption.currentScreen = "information";

                    for(let index = 0; index < data.body.photoList.length; index++){
                        data.body.photoList[index]["image"] = `${Strings.multimeadiHost}${data.body.photoList[index].filePath}${data.body.photoList[index].fileName}`;
                    }

                    this.setState({couponInformation: data.body});
                }else{
                    finalOption.titleAlert = "Este pedido no ha sido entregado a Publynext.";
                    finalOption.messageAlert = "";
                    finalOption.iconAlert = "warning";
                }
            }else{
                finalOption.titleAlert = "Error en la Transacción.";
                finalOption.messageAlert = "Se produjo un error al procesar la petición.";
                finalOption.iconAlert = "error";
            }
        }).catch(error => {
            finalOption.titleAlert = "Error en la Conexión.";
            finalOption.messageAlert = "Por favor revise su conexión a Internet.";
            finalOption.iconAlert = "error";
        });


        this.setState({
            couponInformation: finalOption.couponInformation,
            currentScreen: finalOption.currentScreen
        });

        if(finalOption.showAlert){
            swal(finalOption.titleAlert, finalOption.messageAlert, finalOption.iconAlert);
        }
    }

    onChatWhatsappClickEvent = (numberToChat) => {
        window.open(`https://api.whatsapp.com/send?phone=593${numberToChat.substring(1)}`, "WhatsApp");
    }

    onKeyDownEvent = (event) => {
        if(event.key === 'Enter'){
            this.onSearchButtonClickEvent();
        }
    }

    //Render Section
    renderLoadingComponent = (classes) => {
        //Lottie Options
        const loadingAnimationOptions = {
            loop: true,
            autoplay: true,
            animationData: LoadingAnimation,
            rendererSettings: {
                preserveAspectRatio: "none"
            }
        };

        return(
            <div className = {classes.loadingContainer}>
                <Lottie 
                    options = {loadingAnimationOptions}
                    style = {
                        this.props.screenWidth < 600 ?
                            { width: "90vw", height: "90vw"}
                        : this.props.screenWidth < 960 ?
                            { width: "80", height: "80vw"}
                        : this.props.screenWidth < 1280 ?
                            { width: "55vw", height: "55vw"}
                        : this.props.screenWidth < 1920 ?
                            { width: "50vw", height: "50vw"}
                        : { width: "40vw", height: "40vw"}
                    }
                />
            </div>
        );
    }

    renderInformationComponent = (classes) => {
        return(
            <div className = {classes.informationContainer}>
                {/* {this.renderSenderInformationComponent(classes)} */}
                {this.renderReceiverInformationComponent(classes)}
                {this.renderDeliveryingInformationComponent(classes)}
                {this.renderComplainingInformationComponent(classes)}
            </div>
        );
    }

    renderSenderInformationComponent = (classes) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer}>
                    <img className = {classes.titleIcon} src = "/sender-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Información del Remitente</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.itemTitleText}>Empresa</Typography>
                    <Typography className = {classes.itemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.senderName ? this.capitalizeString(this.state.couponInformation.senderName) : "Nombre de la Empresa"}</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.itemTitleText}>Producto</Typography>
                    <Typography className = {classes.itemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.deliveryProduct ? this.capitalizeString(this.state.couponInformation.deliveryProduct) : "Nombre del Producto"}</Typography>
                </div>
            </Paper>
        );
    }

    renderReceiverInformationComponent = (classes) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer}>
                    <img className = {classes.titleIcon} src = "/receiver-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Información del Destinatario</Typography>
                </div>

                <div className = {classes.globalContainer}>
                    <div className = {classes.receiverItemSectionContainerLeft}>
                        <Typography className = {classes.receiverItemTitleText}>Nombre:</Typography>
                        <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receiverName ? this.capitalizeString(this.state.couponInformation.receiverName) : "Nombre del Destinatario"}</Typography>
                    </div>

                    <div className = {classes.receiverItemSectionContainerRight}>
                        <Typography className = {classes.receiverItemTitleText}>Ciudad:</Typography>
                        <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.destinationCity ? this.capitalizeString(this.state.couponInformation.destinationCity) : "Nombre de la Ciudad de destino"}</Typography>
                    </div>
                </div>

                <div className = {classes.globalContainer}>
                    <div className = {classes.receiverItemSectionContainerLeft}>
                        <Typography className = {classes.receiverItemTitleText}>Dirección:</Typography>
                        <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receiverAddress ? this.capitalizeString(this.state.couponInformation.receiverAddress) : "Dirección del Destinatario"}</Typography>
                    </div>

                    <div className = {classes.receiverItemSectionContainerRight}>
                        <Typography className = {classes.receiverItemTitleText}>Celular:</Typography>
                        <Typography className = {classes.receiverItemContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receiverCellPhone ? this.capitalizeString(this.state.couponInformation.receiverCellPhone) : "Sin Datos"}</Typography>
                    </div>
                </div>
            </Paper>
        );
    }

    renderDeliveryingInformationComponent = (classes) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer}>
                    <img className = {classes.titleIcon} src = "/delivery-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Información de Entrega:</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.deliveryingTitleText}>Remitente:</Typography>
                    <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.senderName ? this.capitalizeString(this.state.couponInformation.senderName) : "Sin Asignar"}</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.deliveryingTitleText}>Producto:</Typography>
                    <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.deliveryProduct ? this.capitalizeString(this.state.couponInformation.deliveryProduct) : "Sin Asignar"}</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.deliveryingTitleText}>Recibido en Bodega:</Typography>
                    <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receptionDate ? this.state.couponInformation.receptionDate.split(" ")[0] : "Sin Asignar"}</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.deliveryingTitleText}>Asignado a Courier:</Typography>
                    <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.couponAssigmentDate && this.state.couponInformation.couponStatusName && this.state.couponInformation.couponStatusName !== "AU" ? this.state.couponInformation.couponAssigmentDate.split(" ")[0] : "Sin Asignar"}</Typography>
                </div>

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.deliveryingTitleText}>Nombre Courier:</Typography>
                    <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.couponStatusName && this.state.couponInformation.courierAssignedName && this.state.couponInformation.couponStatusName !== "AU"  ? this.capitalizeString(this.state.couponInformation.courierAssignedName) : "Sin Asignar"}</Typography>
                </div>

                {this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus !== "AU" ?
                    <div className = {classes.itemSectionContainer}>
                        <Typography className = {classes.deliveryingTitleText}>Celular de Contacto:</Typography>

                        <div className = {classes.deliveryingStatusContentText}>
                            <Typography variant = "inherit" style = {{color: Colors.primary, textTransform: "capitalize", marginRight: "2vw"}} noWrap = {false}>{this.state.couponInformation && this.state.couponInformation.courierAssignedCellPhone ? this.state.couponInformation.courierAssignedCellPhone : "Sin Estado"}</Typography>
                            <Button
                                variant = "contained"
                                color = "primary"
                                size = "small"
                                onClick = {() => this.onChatWhatsappClickEvent(this.state.couponInformation.courierAssignedCellPhone)}
                                className = {classes.buttonsStyleAux}
                                endIcon = {<WhatsApp/>}
                            >
                                Chatear por WhatsApp
                            </Button>
                        </div>
                    </div>
                : null}

                <div className = {classes.itemSectionContainer}>
                    <Typography className = {classes.deliveryingTitleText} >Estado:</Typography>
                    <Typography className = {classes.statusContentText} noWrap = {true} style = {{color: Colors.secondary, fontWeight: "bold"}}>{this.state.couponInformation && this.state.couponInformation.couponStatusName ? this.capitalizeString(this.state.couponInformation.couponStatusName) : "Sin Estado"}</Typography>
                </div>

                {this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "EN" ?
                    <div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Fecha Entrega:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByDate && this.state.couponInformation.couponStatus === "EN"  ? this.state.couponInformation.receivedByDate.split(" ")[0] : "Sin Datos"}</Typography>
                        </div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Recibido Por:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByName) : "Sin Datos"}</Typography>
                        </div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Parentesco:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByRelationshipName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByRelationshipName) : "Sin Datos"}</Typography>
                        </div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Observación:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.receivedByRelationshipName && this.state.couponInformation.couponStatus === "EN"  ? this.capitalizeString(this.state.couponInformation.receivedByObservation) : "Sin Datos"}</Typography>
                        </div>
                        <div className = {classes.itemSectionContainer} style = {{marginBottom: 10, alignItems: "center", justifyContent: "center"}}>
                            <Button
                                variant = "contained"
                                color = "primary"
                                size = "small"
                                onClick = {() => this.setState({openMultimediaDialog: true})}
                                className = {classes.buttonsStyle}
                                endIcon = {<PhotoLibrary/>}
                            >
                                Ver Prueba de Entrega / Fotos
                            </Button>
                        </div>
                    </div>
                : null}

                {this.state.couponInformation && this.state.couponInformation.couponStatus && this.state.couponInformation.couponStatus === "RE" ?
                    <div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Fecha Entrega:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.rejectingDate && this.state.couponInformation.couponStatus === "RE"  ? this.state.couponInformation.rejectingDate.split(" ")[0] : "Sin Datos"}</Typography>
                        </div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Motivo:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.rejectingReasonName && this.state.couponInformation.couponStatus === "RE"  ? this.capitalizeString(this.state.couponInformation.rejectingReasonName) : "Sin Datos"}</Typography>
                        </div>
                        <div className = {classes.itemSectionContainer}>
                            <Typography className = {classes.deliveryingTitleText}>Observación:</Typography>
                            <Typography className = {classes.deliveryingContentText} noWrap = {true}>{this.state.couponInformation && this.state.couponInformation.rejectingObservation && this.state.couponInformation.couponStatus === "RE"  ? this.capitalizeString(this.state.couponInformation.rejectingObservation) : "Sin Datos"}</Typography>
                        </div>
                    </div>
                : null}
            </Paper>
        );
    }

    renderComplainingInformationComponent = (classes) => {
        return(
            <Paper className = {classes.senderInfoContainer}>
                <div className = {classes.tittleSectionContainer}>
                    <img className = {classes.titleIcon} src = "/complain-icon.png" alt = "sender-info"/>
                    <Typography className = {classes.titleSectionText}>Reportar atraso de Entrega</Typography>
                </div>

                <div className = {classes.complainingTitleContainer}>
                    <Typography className = {classes.complainingTitleText} style = {{textAlign: "center"}}>Llame al {this.state.couponInformation && this.state.couponInformation.complainingPhoneNumber ? this.state.couponInformation.complainingPhoneNumber : "Sin Datos"} o </Typography>
                    <Button
                        variant = "contained"
                        color = "primary"
                        fullWidth = {false}
                        size = "small"
                        onClick = {() => this.onChatWhatsappClickEvent(this.state.couponInformation.complainingPhoneNumber)}
                        className = {classes.buttonsStyle}
                        endIcon = {<WhatsApp/>}
                    >
                        Reporte por WhatsApp 
                    </Button>
                </div>
            </Paper>
        );
    }

    renderMultimediaDialog = (classes) => {
        return(
            
                <Dialog
                    open = {this.state.openMultimediaDialog}
                    TransitionComponent = {Transition}
                    keepMounted
                    fullWidth = {true}
                    maxWidth = {'lg'}
                    onClose = {() => this.setState({openMultimediaDialog: false})}
                    aria-labelledby = "alert-dialog-slide-title"
                    aria-describedby = "alert-dialog-slide-description"
                >
                    <DialogContent  className = {classes.dialogContainer}>
                        {this.state.couponInformation && this.state.couponInformation.photoList ?
                            <Carousel
                                data = {this.state.couponInformation.photoList}
                                time = {1500}
                                width = {2080}
                                height = {parseFloat(this.props.screenHeight) * 0.75}
                                radius = "10px"
                                slideNumber = {true}
                                slideNumberStyle = {{ fontSize: '20px', fontWeight: 'bold', color: Colors.primary }}
                                automatic = {true}
                                dots = {true}
                                pauseIconColor = "white"
                                pauseIconSize = "40px"
                                slideBackgroundColor = "darkgrey"
                                slideImageFit = "contain"
                                thumbnails = {false}
                                style = {{
                                    textAlign: "center",
                                    maxWidth: "1000",
                                    maxHeight: "800",
                                    margin: "auto",
                                }}
                            />
                        : null}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant = "contained"
                            color = "primary"
                            size = "small"
                            onClick = {() => this.setState({openMultimediaDialog: false})}
                            style = {{marginRight: 25}}
                            className = {classes.buttonsStyle}
                        >
                            Cerrar 
                        </Button>
                    </DialogActions>
                </Dialog>
            
        );
    }

    componentToRender = {
        loading: (classes) => this.renderLoadingComponent(classes),
        information: (classes) => this.renderInformationComponent(classes),
    }

    renderSearchComponent = (classes) => {
        return(
            <div className = {classes.searchComponent}>
                <div className = {classes.searchContainer}>
                    <div className = {classes.searchIcon}>
                        <Search/>
                    </div>
                    <InputBase
                        placeholder = {SearchByCodeNumberScreenStrings.searchHintText}
                        value = {this.state.codeNumber}
                        onKeyDown = {this.onKeyDownEvent}
                        onChange = {(event) => this.setState({codeNumber: event.target.value})}
                        onBlur = {() => this.setState({codeNumber: this.state.codeNumber.trim()})}
                        className = {classes.inputContainer}
                        classes = {{
                            root: classes.inputRoot,
                            input: classes.inputComponent,
                        }}
                        inputProps = {{ 'aria-label': 'search' }}
                    />
                </div>

                <Button onClick = {this.onSearchButtonClickEvent}  variant = "contained" className = {classes.searchButton}>{SearchByCodeNumberScreenStrings.searchButtonText}</Button>
            </div>
        );
    }

    render(){
        const {classes} = this.props;
        
        return(
            <div className = {classes.mainContainer}>
                <Typography className = {classes.titleText}>{SearchByCodeNumberScreenStrings.titleText}</Typography>
                
                {this.renderSearchComponent(classes)}

                {this.state.currentScreen && this.state.currentScreen.trim().length > 0 && this.componentToRender[this.state.currentScreen] ?
                    this.componentToRender[this.state.currentScreen](classes)
                : null}
                {this.renderMultimediaDialog(classes)}
            </div>
        );
    }
}

const SearchByCodeNumberScreen = (props) => (
    <SearchByCodeNumberComponent
        {...props}
        params = {useParams()}
    />
);
export default withStyles(SearchByCodeNumberScreenStyles)(SearchByCodeNumberScreen);